
















































































import {Component, Ref, Vue, Prop, Watch} from 'vue-property-decorator';
import {OrderGetByIdResult, OrderReturnResult} from "@/models/order/OrderGetByIdResult";
import {formatNumber} from "@/common";

@Component({
})

export default class OrderDetailList extends Vue {
    @Prop({required: true}) data!: any;
    order: OrderGetByIdResult = new OrderGetByIdResult();
    vatUnit: string = "";

    @Watch('data')
    onDataChange(newData: any){
        this.order = newData;
    }

    formatNumber(value: any) {
        return formatNumber(value);
    }
}
