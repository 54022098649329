











































import {Ref, Vue, Prop, Component, Watch, Emit} from 'vue-property-decorator';
import UploadFileButton from "@/components/upload-file/UploadFileButton.vue";
import { FileResult } from "@/components/upload-file/FileResult";
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import {OrderService} from '@/views/order/OrderService';
import { FileService } from "@/services/FileService";

@Component({
    components: {
        UploadFileButton
    }
})
export default class OrderUploadFiles extends Vue {
    @Prop({default: []}) data !: FileResult[];
    @Prop({default: ''}) orderId !: string;

    private orderService: OrderService;
    private fileService: FileService;

    constructor() {
        super();

        this.orderService = new OrderService();
        this.fileService = new FileService();
    }

    files: FileResult[] = new Array<FileResult>();

    @Watch('data', {deep: true, immediate: true})
    onDataChange(newValue: FileResult[]){
        this.files = newValue;
    }

    async onImageChanged(data: FileResult[]) {
        data.forEach(element => {
            if (element) {
                this.files.push(element);
            }
        });

        data = _filter(data, (item: FileResult) => {
            return item;
        }) as FileResult[]

        let fileIds = _map(data, 'id');

        if (this.orderId) {
            if (!fileIds || fileIds.length === 0){
                this.$vs.notify({
                    title: '',
                    text: 'Tệp không đúng định dạng. Vui lòng chọn lại.',
                    color: "danger"
                });

                return ;
            }

            try {
                this.$vs.loading();

                let result = await this.orderService.saveAttachFiles(this.orderId, fileIds);

                this.$vs.loading.close();

                if (result.code > 0) {
                    this.$vs.notify({
                        color: 'success',
                        title: '',
                        text: 'Thêm mới tệp đính kèm thành công'
                    })   
                }
            } catch (error) {
                this.$vs.loading.close();

                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: "danger"
                });

                return;
            }

        }
        else{
            this.$emit("addSuccess", fileIds);
        }
    }

    remove(id: string){
        if (this.orderId) {
            this.confirmDeleteRecord(id);
        }
        else{
            this.files = _filter(this.files, (file: FileResult) => {
                return file.id !== id;
            });
        }
    }

    confirmDeleteRecord(id: string) {
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('ConfirmDelete'),
            text: 'Bạn có chắc chắn xóa không?',
            accept: this.deleteRecord,
            acceptText: this.$t("Delete"),
            parameters: [id] 
        })
    }

    async deleteRecord(parameters: any) {
        let id = parameters[0] as string;

        try {
            const result = await this.orderService.deleteAttachFile(id);

            if (result) {
                this.$vs.notify({
                    color: 'success',
                    title: '',
                    text: 'Xóa tệp đính kèm thành công'
                })

                this.files = _filter(this.files, (file: FileResult) => {
                return file.id !== id;
            });
            }
        } catch (error) {
            this.$vs.notify({
                title: '',
                text: error.message,
                color: "danger"
            });

            return;
        }
    }

    download(file: FileResult){
        this.fileService.download(file);
    }
}
