





































































































































































































































































































































import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {OrderService} from '@/views/order/OrderService';
import {ReturnService} from '@/views/order/ReturnService';

import _each from 'lodash/each';
import _filter from 'lodash/filter';
import {FormType, Status} from "@/constants/Order";
import {formatNumber} from "@/common";
import {OrderGetByIdResult, OrderReturnResult} from "@/models/order/OrderGetByIdResult";
import {Money} from 'v-money';
import {Getter} from "vuex-class";
import moment from 'moment';
import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
import {CustomerService} from "@/services/customer/CustomerService";
import PaymentTimeline from "@/components/order/PaymentTimeline.vue";
import OrderPrint from "@/views/order/order-print/OrderPrint.vue";
import {OptionOrderStatus} from '@/models/order/OrderSearchResult';
import {TabState} from "@/store/order/order-state";
import OrderDetailList from "@/views/order/OrderDetailList.vue";
import OrderUploadFiles from '@/views/order/order-edit/OrderUploadFiles.vue';
import {UserConfigService} from "@/store/user-management/UserConfigService";

@Component({
    components: {
        Money,
        // OrderPayment: () => import(/* webpackChunkName: "OrderPayment" */ '@/views/order/order-edit/OrderPayment.vue'),
        PaymentTimeline,
        OrderPrint,
        OrderDetailList,
        OrderUploadFiles
    }
})

export default class OrderView extends Vue {
    @Getter("oidcUser") oidcUser!: any;
    @Ref('orderPaymentRef') orderPaymentRef: any;
    @Ref('orderPrint') orderPrint!: any;
    @Ref('cancelReason') cancelReason!: any;

    private orderService: OrderService;
    private returnService: ReturnService;
    private customerService: CustomerService;
    private userConfigService: UserConfigService;

    constructor() {
        super();

        this.orderService = new OrderService();
        this.returnService = new ReturnService();
        this.customerService = new CustomerService();
        this.userConfigService = new UserConfigService();
    }

    order: OrderGetByIdResult = new OrderGetByIdResult();
    // vatUnit: string = "";
    // totalMoney: number = 0;
    wasSidebarOpen: any = null;
    // activeCancelPrompt: boolean = false;
    reason: string = '';
    orderStatus = Status;
    paymentTimelineData: any = null;
    listStatus: Array<any> | undefined = [];
    popupReasonActive: Boolean = false;
    isUpdateOrderStatus: boolean = false;
    selectedStatus: Status = Status.Draft;
    popupReasonTitle: string = '';

    get currentUser() {
        return this.oidcUser;
    }

    async beforeMount() {
        let orderId = this.$route.params.id;

        try {
            this.openLoading(true);
            let result = await this.orderService.getById(orderId);

            if (result.code > 0) {
                let datetimeFormat = 'DD-MM-YYYY HH:mm';
                let orderData = result.data as OrderGetByIdResult;

                if (orderData) {
                    orderData.orderDate = moment(orderData.orderDate).format(datetimeFormat);
                    orderData.createTime = moment(orderData.createTime).format(datetimeFormat);

                    _each(orderData.returns, (item: OrderReturnResult) => {
                        item.createTime = moment(item.createTime).format(datetimeFormat);
                    });

                    if (orderData.deliveryDate) {
                        orderData.deliveryDate.from = moment(orderData.deliveryDate.from).format(datetimeFormat);
                        orderData.deliveryDate.to = moment(orderData.deliveryDate.to).format(datetimeFormat);
                    }

                    this.order = orderData;

                    this.paymentTimelineData = {
                        payments: orderData.payments,
                        orderId: orderData.id,
                        orderCode: orderData.code,
                        paidPrice: orderData.paidPrice,
                        // totalAmount: orderData.totalPrice,
                        totalAmountReturn: orderData.totalAmountReturn,
                        remainPrice: orderData.remainPrice
                    }
                }
            }

            this.openLoading(false);
        } catch (error) {
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        }

        let listStatus = await this.orderService.getListStatus();

        if (listStatus && listStatus.length) {
            this.listStatus = _filter(listStatus, status => {
                let value = status.value;

                return value !== Status.TotalOrder && value !== Status.NotCompleted
            });
        }

        let result = await this.userConfigService.getUserConfig(this.currentUser.userId);

        if (result.code) {
            let userConfig = result.data;

            if (userConfig)
                this.isUpdateOrderStatus = userConfig.isUpdateOrderStatus
        }
    }

    mounted() {
        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }

    @Watch('selectedStatus')
    onSelectedStatusChange(value: Status) {
        let text = '';

        switch (value) {
            case Status.Canceled:
                text = 'hủy';
                break;
            case Status.GoodsNotDelivered:
                text = 'không giao được';
                break;
            case Status.WaitingForReturnAndExchange:
                text = 'chờ đổi hàng';
                break;
        }

        this.popupReasonTitle = 'Lý do ' + text + ' đơn hàng';
    }

    formatNumber(value: any) {
        return formatNumber(value);
    }

    // openOrderPayment() {
    //     this.orderPaymentRef.openPopup();
    // }

    // onPaymentMethodSaveSuccessed(value: number, items: Array<Payment>) {
    //     _each(items, (item: Payment) => {
    //         item.money = parseFloat((item.money ? item.money : 0).toString());
    //     });
    //
    //     this.order.payments = items;
    //     this.order.paidPrice = value;
    // }

    print() {
        this.orderPrint.print([this.order.id]);
    }

    get chipColor() {
        return (value: any) => {
            return new OptionOrderStatus(value).color;
        }
    }

    get statusName() {
        return (value: any) => {
            return new OptionOrderStatus(value).name;
        }
    }

    get isEdit() {
        return this.orderService.isEdit(this.order.status);
    }

    get isDelete() {
        return this.orderService.isDelete(this.order.status);
    }

    get isReturn() {
        return this.orderService.isReturn(this.order.status);
    }

    get isCancel() {
        return this.orderService.isCancel(this.order.status);
    }

    confirmDeleteRecord() {
        let orderCode = this.order.code;

        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('ConfirmDelete'),
            text: this.$t('ConfirmOrderDeleteText', {data: orderCode}),
            accept: this.deleteRecord,
            acceptText: this.$t("Delete")
        })
    }

    async deleteRecord() {
        try {
            this.$vs.loading();
            const result = await this.orderService.delete(this.order.id);

            if (result.code > 0) {
                this.$vs.loading.close();
                this.$router.push({name: 'orderList'});

                this.$vs.notify({
                    color: 'success',
                    title: '',
                    text: 'Xóa đơn hàng thành công'
                })
            }
        } catch (error) {
            this.$vs.loading.close();

            this.$vs.notify({
                title: '',
                text: error.message,
                color: "danger"
            });

            return;
        }
    }

    confirmCancel() {
        this.selectedStatus = Status.Canceled;
        this.popupReasonActive = true;

        // this.activeCancelPrompt = true;
        // this.$nextTick(() => this.setFocusOnInput());
    }

    // setFocusOnInput() {
    //     let element = this.cancelReason;
    //
    //     if (element && element.$el) {
    //         setTimeout(() => {
    //             element.$el.querySelector('input').focus();
    //         }, 100);
    //     }
    // }

    async accept() {
        await this.changeStatus(this.order.id, this.selectedStatus, this.reason)
    }

    // cancel() {
    //     this.activeCancelPrompt = false;
    // }

    get returnChipColor() {
        return (value: any) => {
            return value === 0 ? 'danger' : 'success';
        }
    }

    get returnStatusName() {
        return (value: any) => {
            return value === 0 ? 'Đã hủy' : 'Đã trả';
        }
    }

    orderReturn() {
        this.$router.push({name: 'orderAdd'});

        const tab: TabState = {
            id: this.order.id,
            type: FormType.Return,
        };

        this.$store.dispatch('order/addTab', tab);
    }

    async onChangeStatus(status: Status) {
        if (status === Status.GoodsNotDelivered || status === Status.Canceled || status === Status.WaitingForReturnAndExchange) {
            this.popupReasonActive = true;
            this.selectedStatus = status;

            return;
        }

        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Xác nhận`,
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: `Bạn có muốn cập nhật trạng thái không?`,
            accept: async () => {
                await this.changeStatus(this.order.id, status, this.reason);
            }
        });
    }

    async changeStatus(orderId: string, status: Status, reason: string) {
        try {
            this.openLoading(true);
            let message = "Cập nhật trạng thái thành công!";
            let result = await this.orderService.updateStatus(this.order.id, status, reason);
            this.openLoading(false);
            this.order.status = status;

            if (status === Status.GoodsNotDelivered || status === Status.Canceled || status === Status.WaitingForReturnAndExchange) {
                this.order.cancelReason = reason;
                this.reason = '';
            }

            if (status === Status.Canceled)
                message = "Hủy đơn hàng thành công!";

            if (result.code > 0) {
                this.popupReasonActive = false;

                this.$vs.notify({
                    title: '',
                    text: message,
                    color: "success"
                });
            }
        } catch (e) {
            this.popupReasonActive = false;
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: e.message,
                color: "danger"
            });
        }
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }
}
