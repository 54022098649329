






















































































































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import Printd from 'printd'
import {OrderService} from "@/views/order/OrderService";
import {OrderPrintResult} from "@/views/order/order-print/OrderPrintResult";
import {ActionResult} from "@/models/ActionResult";
import {CustomerType} from "@/constants/Customer";
import {Money} from 'v-money'
import moment from "moment";
import {PrintType} from '@/constants/Order';
import VueQrcode from 'vue-qrcode';

@Component({
    components: {
        Money,
        VueQrcode
    }
})
export default class OrderPrint extends Vue {
    customerType = CustomerType;
    isLoading = false;
    listOrder: any = [];
    moneyOptions = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };
    quantityOptions = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    }
    css = `
        @page { size: auto;  margin: 5mm; }

        .print-page {
            font-size: 13px;
        }

        .w100pc {
            width: 100%;
        }

        .text-right {
            text-align: right;
        }

        .note {
            font-style: italic;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        table th, table, td {
            border: 1px solid #000;
            padding: 5px;
        }

        table.no-border, table.no-border tr td, table.no-border tr th {
            border: none !important;
        }

        .company-name {
            text-transform: uppercase;
            font-size: 16px;
        }

        .company-name {
            text-transform: uppercase;
            font-size: 16px;
        }

        h5 {
            margin-top: 10px;
            margin-bottom: 5px;
        }

        .text-center {
            text-align: center;
        }

        .bold {
            font-weight: bold;
        }

        input.money {
            font-family: 'Calibri Light' !important;
            border: none;
            text-align: right;
            background: transparent;
            font-size: 14px;
        }

        .print-container {
            font-size: 16px !important;
            font-family: 'Calibri Light' !important;
        }

        .print-container table th, .print-container table, td {
            border: 1px solid #000000 !important;
            padding: 8px !important;
        }

        .print-container table.no-border, .print-container table.no-border tr td, .print-container table.no-border tr th {
            border: none !important;
            padding-left: 0px !important;
        }

        .print-container table.no-border tr td {
            padding-bottom: 2px !important;
        }

        .print-container h5 {
            margin: 0 !important;
            padding: 4px 0px 4px 5px !important;
            font-size: 16px !important;
            font-family: 'Calibri Light' !important;
        }

        .italic {
            font-style: italic !important;
        }

        .print-container input.money {
            border: none !important;
            text-align: right !important;
            background: transparent !important;
            font-size: 16px !important;
            font-family: 'Calibri Light' !important;
        }

        .print-container .productTable{
            margin-top: 10px !important;
        }

        @media print {
            footer {page-break-after: always;}

            .print-container h5 {
                background-color: #A6A6A6 !important;
                -webkit-print-color-adjust: exact !important;
            }
        }
    `;

    popupPrintActive: boolean = false;
    printType: PrintType = PrintType.All;
    orderIds: Array<string> = [];

    get isAll() {
        return this.printType == PrintType.All;
    }

    print(orderIds: string[]) {
        if (!orderIds || orderIds.length === 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Vui lòng chọn đơn hàng cần in",
                color: "warning"
            });
            return;
        }

        this.orderIds = orderIds;
        this.printType = PrintType.All;
        this.popupPrintActive = true;
    }

    accept() {
        const orderService = new OrderService();
        this.isLoading = true;
        this.listOrder = [];
        orderService.getPrintDetail(this.orderIds)
            .then((result: ActionResult<OrderPrintResult>[]) => {
                this.isLoading = false;
                if (result && result.length > 0) {
                    result.forEach((item: ActionResult<OrderPrintResult>) => {
                        if (item.code > 0) {
                            const data = item.data as OrderPrintResult;
                            if (data) {
                                data.issuedDate = data.issuedDate ? moment(data.issuedDate).format('DD/MM/YYYY hh:mm') : '';
                                data.createTime = data.createTime ? moment(data.createTime).format('DD/MM/YYYY hh:mm') : '';

                                if (data.deliveryDate) {
                                    data.deliveryDate.from = data.deliveryDate.from ? moment(data.deliveryDate.from).format('DD/MM/YYYY hh:mm') : '';
                                    data.deliveryDate.to = data.deliveryDate.to ? moment(data.deliveryDate.to).format('DD/MM/YYYY hh:mm') : '';
                                }

                                this.listOrder.push(data);
                            }
                        }
                    });
                    setTimeout(() => {
                        const printer = new Printd();
                        const orderDetailPrintElement: any = this.$refs.orderPrintPage;
                        printer.print(orderDetailPrintElement, [
                            this.css
                        ])
                    }, 500);
                }
            })
            .catch(() => this.isLoading = false);
    }
}
