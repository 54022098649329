import axios from 'axios';
import { FileResult } from "@/components/upload-file/FileResult";

export class FileService {
    url = `${process.env.VUE_APP_GATEWAY_URL}file/api/v1/files`;

    async download(file: FileResult) {
        let result = await axios.get(`${this.url}/${file.id}`, {responseType: 'blob'}) as any;
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;
        
        link.setAttribute('download', file.fileName);

        document.body.appendChild(link);
        link.click();
        return true;
    }
}
